import {
  FundingSourceStatus,
  TransactionTemplateFragment,
  namedOperations,
  useCalculateFeeQuery,
  useCreateTemplateRequestWebMutation,
  usePaymentOccasionQuery,
  useUpdateUserMutation,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { formatAmount } from "@earnnest-e2-frontend/platform-ui/src/mantine/AmountText"
import { PanelFooter } from "@earnnest-e2-frontend/platform-ui/src/mantine/Panel"
import {
  ActionIcon,
  Alert,
  Badge,
  Button,
  Group,
  Input,
  MultiSelect,
  Radio,
  Select,
  Space,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core"
import { useForm, yupResolver } from "@mantine/form"
import { notifications } from "@mantine/notifications"
import { State } from "country-state-city"
import numeral from "numeral"
import { useEffect } from "react"
import { RiQuestionFill } from "react-icons/ri"
import MaskedInput from "react-text-mask"
import { createNumberMask } from "text-mask-addons"
import * as yup from "yup"
import EscrowSearchSelect from "./EscrowSearchSelect"
import { useHistory } from "react-router-dom"
import { openConfirmModal } from "@mantine/modals"

export function phoneMask(rawValue: string) {
  const phoneMaskWithoutCountryCode = [/[1-9]/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/] // prettier-ignore
  if (rawValue.length > 0 && rawValue[0] === "1") {
    return ["1", "-", ...phoneMaskWithoutCountryCode]
  }
  return phoneMaskWithoutCountryCode
}

export function isValidAmount(amount: string) {}

export function isValidState(state: string) {
  return !!State.getStatesOfCountry("US").find(
    (x) => x.isoCode === state.toUpperCase(),
  )
}

export function isValidEmail(email: string) {
  if (!email || !email.includes("@") || !email.includes(".")) return false
  try {
    yup.string().email().required().validateSync(email)
    return true
  } catch (error) {
    return false
  }
}

export default function CreateEMDRequestForm({
  transactionTemplate,
  onSubmitSuccess,
}: {
  transactionTemplate?: TransactionTemplateFragment
  onSubmitSuccess?: (transactionTemplate: TransactionTemplateFragment) => void
}) {
  const history = useHistory()

  const userQuery = useUserQuery()
  const user = userQuery.data?.user
  const verifiedFundingSources = user?.fundingSources?.filter(
    (x) => x.status === FundingSourceStatus.Verified,
  )

  const existingFormData = transactionTemplate?.formData?.reduce<{
    [name: string]: string
  }>((obj, field) => {
    obj[field.name] = field.value
    return obj
  }, {})

  const form = useForm({
    initialValues: {
      amount: transactionTemplate?.amount || "",
      addressLine1: existingFormData?.["address_line_1"] || "",
      addressLine2: existingFormData?.["address_line_2"] || "",
      city: existingFormData?.["city"] || "",
      stateOrRegion: existingFormData?.["state_or_region"] || "",
      postalCode: existingFormData?.["postal_code"] || "",
      escrowAccountId: transactionTemplate?.escrowAccount?.id || "",
      payerFirstName: transactionTemplate?.buyer?.firstName || "",
      payerLastName: transactionTemplate?.buyer?.lastName || "",
      payerEmail: transactionTemplate?.receiverEmail || "",
      payerPhone: transactionTemplate?.buyer?.phone || "",
      additionalEmails: transactionTemplate?.additionalEmails || [],
      agentCoveringFee: user?.agentCoveringFees ? "yes" : "no",
      agentFundingSourceId: user?.defaultFundingSourceId || null,
      // helpers
      escrowAccountLimit:
        transactionTemplate?.escrowAccount?.paymentOccasion?.limit ||
        100_000_00,
      newEmail: "",
    },
    validate: yupResolver(
      yup.object({
        amount: yup
          .string()
          .required("Required")
          .test("Min", `Minimum $48`, (x) => numeral(x).value() >= 48)
          .when("escrowAccountLimit", (value, schema) => {
            const limit = Number(value) / 100
            return schema.test(
              "Max",
              `Maximum ${numeral(limit).format("$0,0")}`,
              (x) => numeral(x).value() <= limit,
            )
          }),
        addressLine1: yup.string().required("Required"),
        addressLine2: yup.string(),
        city: yup.string().required("Required"),
        stateOrRegion: yup
          .string()
          .required("Required")
          .test("State", "Invalid State Code", isValidState),
        postalCode: yup.string().required("Required"),
        escrowAccountId: yup.string().required("Required"),
        payerFirstName: yup.string().required("Required"),
        payerLastName: yup.string().required("Required"),
        payerEmail: yup.string().email("Invalid Email").required("Required"),
        payerPhone: yup.string(),
        agentCoveringFee: yup.string().oneOf(["yes", "no"]),
        agentFundingSourceId: yup.string().when("agentCoveringFee", {
          is: (val) => val === "yes",
          then: (schema) => schema.required("Required"),
        }),
        additionalEmails: yup.array().of(yup.string().email()),
        newEmail: yup.string().email("Invalid Email"),
        escrowAccountLimit: yup.number(),
      }),
    ),
  })

  const paymentOccasionQuery = usePaymentOccasionQuery({
    variables: {
      id: form.values.escrowAccountId,
    },
    skip: !form.values.escrowAccountId,
  })
  const paymentOccasion = paymentOccasionQuery.data?.paymentOccasion
  useEffect(() => {
    // need to reset the agentCoveringFee value if payment occasion fee is covered by the org
    if (
      paymentOccasion?.feeConfig?.payer === "party" &&
      form.values.agentCoveringFee === "yes"
    ) {
      form.setFieldValue("agentCoveringFee", "no")
    }
  }, [paymentOccasion, form])

  const calculateFeeQuery = useCalculateFeeQuery({
    variables: {
      amount: parseInt((numeral(form.values.amount).value() * 100).toFixed(0)),
      paymentOccasionId: form.values.escrowAccountId,
      paymentType: "bank_account",
    },
    skip: !form.values.amount || !form.values.escrowAccountId,
  })
  const payerFeeAmount =
    calculateFeeQuery.data?.calculateFee?.platformFeeAmount || 0

  const [updateUser, updateUserState] = useUpdateUserMutation()
  const [
    createTemplateRequestWeb,
    createTemplateRequestWebState,
  ] = useCreateTemplateRequestWebMutation()

  return (
    <form
      autoComplete="off"
      onSubmit={form.onSubmit(async (values) => {
        try {
          if (
            values.agentCoveringFee === "yes" &&
            values.agentFundingSourceId
          ) {
            await updateUser({
              variables: {
                id: user?.id,
                defaultFundingSourceId: values.agentFundingSourceId,
              },
            })
            console.log(
              `Updated user with default funding source: ${values.agentFundingSourceId}`,
            )
          }
          const request = await createTemplateRequestWeb({
            variables: {
              transactionTemplateId: transactionTemplate?.id || undefined,
              amount: parseInt(
                (numeral(values.amount).value() * 100).toFixed(0),
              ),
              escrowAccountId: values.escrowAccountId,
              receiverEmail: values.payerEmail,
              agentCoveringFee: values.agentCoveringFee === "yes",
              additionalEmails: values.additionalEmails,
              formData: [
                {
                  name: "propaddress",
                  value: `${values.addressLine1}${
                    values.addressLine2 ? ` ${values.addressLine2}` : ""
                  }, ${values.city}, ${values.stateOrRegion} ${
                    values.postalCode
                  }`,
                },
                {
                  name: "address_line_1",
                  value: values.addressLine1 || "",
                },
                {
                  name: "address_line_2",
                  value: values.addressLine2 || "",
                },
                { name: "city", value: values.city || "" },
                {
                  name: "state_or_region",
                  value: values.stateOrRegion
                    ? values.stateOrRegion.toUpperCase()
                    : "",
                },
                {
                  name: "postal_code",
                  value: values.postalCode || "",
                },
                { name: "agent_role", value: "buyer" },
                { name: "agentemail", value: user?.email || "" },
                {
                  name: "agentfirst",
                  value: user?.firstName || "",
                },
                {
                  name: "agentlast",
                  value: user?.lastName || "",
                },
                {
                  name: "payerfirst",
                  value: values.payerFirstName || "",
                },
                {
                  name: "payerlast",
                  value: values.payerLastName || "",
                },
                {
                  name: "buyer_phone",
                  value: values.payerPhone || "",
                },
              ],
            },
            refetchQueries: [namedOperations.Query.TransactionTemplates],
          })
          onSubmitSuccess?.(request.data?.createTemplateRequestWeb)
          notifications.show({
            color: "green",
            title: "Success",
            message: "Request sent",
          })
        } catch (error) {
          notifications.show({
            color: "red",
            title: "Error",
            message: error.message,
          })
        }
      })}>
      <Title size="h2" order={2}>
        Request Payment
      </Title>
      <Stack>
        <Space h="xl" />
        <Title order={5}>What’s the earnest money amount?</Title>
        <Group spacing={0}>
          <Text size="sm">Enter the amount in the contract.</Text>
          <Tooltip
            position="bottom"
            multiline={true}
            width={300}
            label="The standard limit is $100,000. If the earnest request is for more than the limit, send multiple requests for the same property.">
            <ActionIcon
              component="a"
              target="_blank"
              href="https://earnnest.com/resources/faq"
              variant="subtle"
              radius="xl">
              <RiQuestionFill size={20} />
            </ActionIcon>
          </Tooltip>
        </Group>
        <Input.Wrapper
          label="Amount"
          {...form.getInputProps("amount")}
          sx={{ width: 200 }}>
          <Input
            placeholder="$0.00"
            component={MaskedInput}
            mask={createNumberMask({ allowDecimal: true })}
            guide={false}
            autoComplete="off"
            {...form.getInputProps("amount")}
          />
        </Input.Wrapper>
        <Space h="xl" />
        <Title order={5}>What’s the property address?</Title>
        <TextInput
          label="Address Line 1"
          autoComplete="off"
          disabled={existingFormData?.hasOwnProperty("address_line_1")}
          {...form.getInputProps("addressLine1")}
        />
        <TextInput
          label="Address Line 2 (Optional)"
          autoComplete="off"
          disabled={existingFormData?.hasOwnProperty("address_line_2")}
          {...form.getInputProps("addressLine2")}
        />
        <Group grow align="start">
          <TextInput
            label="City"
            autoComplete="off"
            sx={{ flexGrow: 3, maxWidth: "auto" }}
            disabled={existingFormData?.hasOwnProperty("city")}
            {...form.getInputProps("city")}
          />
          <Input.Wrapper
            label="State"
            sx={{ width: 100, maxWidth: "auto" }}
            {...form.getInputProps("stateOrRegion")}>
            <Input
              component={MaskedInput}
              mask={[/[a-zA-Z]/, /[a-zA-Z]/]}
              guide={false}
              autoComplete="off"
              disabled={existingFormData?.hasOwnProperty("state_or_region")}
              {...form.getInputProps("stateOrRegion")}
              onChange={(e) =>
                form.setFieldValue(
                  "stateOrRegion",
                  e.target.value.toUpperCase(),
                )
              }
            />
          </Input.Wrapper>
        </Group>
        <TextInput
          label="Zip Code"
          autoComplete="off"
          sx={{ width: 200 }}
          disabled={existingFormData?.hasOwnProperty("postal_code")}
          {...form.getInputProps("postalCode")}
        />
        <Space h="xl" />
        <Title order={5}>Who’s holding escrow?</Title>
        <EscrowSearchSelect
          label="Escrow Holder"
          stateCode={form.values.stateOrRegion}
          initialEscrowAccount={transactionTemplate?.escrowAccount}
          disabled={!!transactionTemplate?.escrowAccount?.id}
          {...form.getInputProps("escrowAccountId")}
          onChange={(value, item) => {
            form.setFieldValue("escrowAccountId", value)
            form.setFieldValue(
              "escrowAccountLimit",
              item?.escrowAccount?.paymentOccasion?.limit || 100_000_00,
            )
          }}
        />
        <Space h="xl" />
        <Title order={5}>Who’s paying?</Title>
        <Group grow align="start">
          <TextInput
            label="First Name"
            autoComplete="off"
            {...form.getInputProps("payerFirstName")}
          />
          <TextInput
            label="Last Name"
            autoComplete="off"
            {...form.getInputProps("payerLastName")}
          />
        </Group>
        <Group grow align="start">
          <TextInput
            label="Email"
            autoComplete="off"
            {...form.getInputProps("payerEmail")}
          />
          <Input.Wrapper label="Phone" {...form.getInputProps("payerPhone")}>
            <Input
              component={MaskedInput}
              mask={phoneMask}
              guide={false}
              autoComplete="off"
              {...form.getInputProps("payerPhone")}
            />
          </Input.Wrapper>
        </Group>
        {paymentOccasion &&
        paymentOccasion?.feeConfig?.schema.payer === "party" &&
        payerFeeAmount ? (
          <>
            <Space h="xl" />
            <Title order={5}>Do you want to cover the processing fee?</Title>
            <Radio.Group {...form.getInputProps("agentCoveringFee")}>
              <Stack spacing="sm">
                <Radio
                  value="no"
                  label={`No, the payer will cover the ${formatAmount(
                    payerFeeAmount,
                  )} fee`}
                />
                <Radio
                  value="yes"
                  label={`Yes, I’ll cover the ${formatAmount(
                    payerFeeAmount,
                  )} fee`}
                />
              </Stack>
            </Radio.Group>
            {form.values.agentCoveringFee === "yes" ? (
              <>
                <Select
                  label="Funding Source"
                  disabled={verifiedFundingSources?.length === 0}
                  placeholder="Select a funding source"
                  data={verifiedFundingSources.map((x) => ({
                    label: x.displayName,
                    value: x.id,
                  }))}
                  {...form.getInputProps("agentFundingSourceId")}
                />
                {verifiedFundingSources?.length > 0 ? (
                  form.values.agentFundingSourceId !==
                  user.defaultFundingSourceId ? (
                    <Text size="sm">
                      <b>NOTE:</b> This will be set as your default funding
                      source for all future fees you decide to cover.
                    </Text>
                  ) : null
                ) : (
                  <Alert
                    color="orange"
                    variant="light"
                    title="No funding sources found">
                    <Stack spacing="xs">
                      <Text size="sm">
                        To cover the processing fee, add a funding source in the
                        Funding Sources section of your dashboard. Verifying a
                        funding source may take 1-2 business days.
                      </Text>
                      <Text
                        color="dark"
                        size="xs"
                        weight="bold"
                        transform="uppercase"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          openConfirmModal({
                            styles: {
                              overlay: {
                                zIndex: 202,
                              },
                            },
                            title: "Are you sure?",
                            children: (
                              <Text size="sm">
                                Any information you have filled out will be
                                lost. Verifying a funding source may take 1-2
                                business days.
                              </Text>
                            ),
                            labels: { confirm: "Confirm", cancel: "Cancel" },
                            onConfirm: () => {
                              history.push("/funding-sources")
                            },
                          })
                        }}>
                        Go to Funding Sources →
                      </Text>
                    </Stack>
                  </Alert>
                )}
              </>
            ) : null}
          </>
        ) : null}
        <Space h="xl" />
        <Title order={5}>Who should receive notifications?</Title>
        <Text size="sm">
          Add email addresses of those you want notified about this payment
          request. Use this section to add others, like listing agent, lender or
          office administrator.
        </Text>
        <Stack spacing="sm" align="start">
          <Badge color="dark" size="lg">
            {user.email} (You)
          </Badge>
          {isValidEmail(form.values.payerEmail) ? (
            <Badge color="dark" size="lg">
              {form.values.payerEmail} (Payer)
            </Badge>
          ) : null}
        </Stack>
        <MultiSelect
          {...form.getInputProps("additionalEmails")}
          placeholder="Add additional notification recipients"
          data={form.values.additionalEmails}
          error={form.errors.additionalEmails || form.errors.newEmail}
          searchable={true}
          searchValue={form.values.newEmail}
          onSearchChange={(query) => {
            form.setFieldValue("newEmail", query.trim())
            form.validateField("newEmail")
          }}
          creatable={true}
          getCreateLabel={(query) => `Click to add new email: ${query}`}
          onCreate={(query) => {
            if (!form.errors.newEmail) {
              form.setFieldValue(
                "additionalEmails",
                form.values.additionalEmails.concat(query.trim()),
              )
              return query
            }
            return
          }}
        />
      </Stack>
      <PanelFooter>
        <Button
          size="lg"
          type="submit"
          color="green"
          loading={
            createTemplateRequestWebState.loading || updateUserState.loading
          }>
          Send Request
        </Button>
      </PanelFooter>
    </form>
  )
}
